<template>
  <div>
    <v-card class="mx-auto containerRotate9 pa-0" color="grey lighten-4">
      <v-card-title class="mt-30 justify-center pt-5 pb-0 text-center">
        <div>
        <v-row class="mt-1">
          <v-col class="font-weight-black text-center headlineText mt-1 pa-0">
            ISPENT EXPENSE TRACKER
          </v-col>
        </v-row>
        <v-row>
          <v-col class="text-uppercase text-center justify-center font-weight-regular
          text-center font-sub-title">
            {{ getEntryTime() }}
            
             <!-- <v-chip color="green" text-color="white" class="pb-0">
                    FREE      
                    <v-icon right>
                      mdi-star
                    </v-icon>
                </v-chip> -->
          </v-col>
        </v-row>
        <v-row>
         
          <v-col class="pa-0 text-right">
           
            <a href="https://apps.apple.com/app/id6499497791?itsct=apps_box_badge&amp;itscg=30200" >
             
             <svg id="livetype" xmlns="http://www.w3.org/2000/svg" width="119.66407" 
             height="40" viewBox="0 0 119.66407 40">
<title>Download app on the app store</title>
<g>
<g>
 <g>
   <path d="M110.13477,0H9.53468c-.3667,0-.729,0-1.09473.002-.30615.002-.60986.00781-.91895.0127A13.21476,13.21476,0,0,0,5.5171.19141a6.66509,6.66509,0,0,0-1.90088.627A6.43779,6.43779,0,0,0,1.99757,1.99707,6.25844,6.25844,0,0,0,.81935,3.61816a6.60119,6.60119,0,0,0-.625,1.90332,12.993,12.993,0,0,0-.1792,2.002C.00587,7.83008.00489,8.1377,0,8.44434V31.5586c.00489.3105.00587.6113.01515.9219a12.99232,12.99232,0,0,0,.1792,2.0019,6.58756,6.58756,0,0,0,.625,1.9043A6.20778,6.20778,0,0,0,1.99757,38.001a6.27445,6.27445,0,0,0,1.61865,1.1787,6.70082,6.70082,0,0,0,1.90088.6308,13.45514,13.45514,0,0,0,2.0039.1768c.30909.0068.6128.0107.91895.0107C8.80567,40,9.168,40,9.53468,40H110.13477c.3594,0,.7246,0,1.084-.002.3047,0,.6172-.0039.9219-.0107a13.279,13.279,0,0,0,2-.1768,6.80432,6.80432,0,0,0,1.9082-.6308,6.27742,6.27742,0,0,0,1.6172-1.1787,6.39482,6.39482,0,0,0,1.1816-1.6143,6.60413,6.60413,0,0,0,.6191-1.9043,13.50643,13.50643,0,0,0,.1856-2.0019c.0039-.3106.0039-.6114.0039-.9219.0078-.3633.0078-.7246.0078-1.0938V9.53613c0-.36621,0-.72949-.0078-1.09179,0-.30664,0-.61426-.0039-.9209a13.5071,13.5071,0,0,0-.1856-2.002,6.6177,6.6177,0,0,0-.6191-1.90332,6.46619,6.46619,0,0,0-2.7988-2.7998,6.76754,6.76754,0,0,0-1.9082-.627,13.04394,13.04394,0,0,0-2-.17676c-.3047-.00488-.6172-.01074-.9219-.01269-.3594-.002-.7246-.002-1.084-.002Z" style="fill: #a6a6a6"/>
   <path d="M8.44483,39.125c-.30468,0-.602-.0039-.90429-.0107a12.68714,12.68714,0,0,1-1.86914-.1631,5.88381,5.88381,0,0,1-1.65674-.5479,5.40573,5.40573,0,0,1-1.397-1.0166,5.32082,5.32082,0,0,1-1.02051-1.3965,5.72186,5.72186,0,0,1-.543-1.6572,12.41351,12.41351,0,0,1-.1665-1.875c-.00634-.2109-.01464-.9131-.01464-.9131V8.44434S.88185,7.75293.8877,7.5498a12.37039,12.37039,0,0,1,.16553-1.87207,5.7555,5.7555,0,0,1,.54346-1.6621A5.37349,5.37349,0,0,1,2.61183,2.61768,5.56543,5.56543,0,0,1,4.01417,1.59521a5.82309,5.82309,0,0,1,1.65332-.54394A12.58589,12.58589,0,0,1,7.543.88721L8.44532.875H111.21387l.9131.0127a12.38493,12.38493,0,0,1,1.8584.16259,5.93833,5.93833,0,0,1,1.6709.54785,5.59374,5.59374,0,0,1,2.415,2.41993,5.76267,5.76267,0,0,1,.5352,1.64892,12.995,12.995,0,0,1,.1738,1.88721c.0029.2832.0029.5874.0029.89014.0079.375.0079.73193.0079,1.09179V30.4648c0,.3633,0,.7178-.0079,1.0752,0,.3252,0,.6231-.0039.9297a12.73126,12.73126,0,0,1-.1709,1.8535,5.739,5.739,0,0,1-.54,1.67,5.48029,5.48029,0,0,1-1.0156,1.3857,5.4129,5.4129,0,0,1-1.3994,1.0225,5.86168,5.86168,0,0,1-1.668.5498,12.54218,12.54218,0,0,1-1.8692.1631c-.2929.0068-.5996.0107-.8974.0107l-1.084.002Z"/>
 </g>
 <g id="_Group_" data-name="&lt;Group&gt;">
   <g id="_Group_2" data-name="&lt;Group&gt;">
     <g id="_Group_3" data-name="&lt;Group&gt;">
       <path id="_Path_" data-name="&lt;Path&gt;" d="M24.76888,20.30068a4.94881,4.94881,0,0,1,2.35656-4.15206,5.06566,5.06566,0,0,0-3.99116-2.15768c-1.67924-.17626-3.30719,1.00483-4.1629,1.00483-.87227,0-2.18977-.98733-3.6085-.95814a5.31529,5.31529,0,0,0-4.47292,2.72787c-1.934,3.34842-.49141,8.26947,1.3612,10.97608.9269,1.32535,2.01018,2.8058,3.42763,2.7533,1.38706-.05753,1.9051-.88448,3.5794-.88448,1.65876,0,2.14479.88448,3.591.8511,1.48838-.02416,2.42613-1.33124,3.32051-2.66914a10.962,10.962,0,0,0,1.51842-3.09251A4.78205,4.78205,0,0,1,24.76888,20.30068Z" style="fill: #fff"/>
       <path id="_Path_2" data-name="&lt;Path&gt;" d="M22.03725,12.21089a4.87248,4.87248,0,0,0,1.11452-3.49062,4.95746,4.95746,0,0,0-3.20758,1.65961,4.63634,4.63634,0,0,0-1.14371,3.36139A4.09905,4.09905,0,0,0,22.03725,12.21089Z" style="fill: #fff"/>
     </g>
   </g>
   <g>
     <path d="M42.30227,27.13965h-4.7334l-1.13672,3.35645H34.42727l4.4834-12.418h2.083l4.4834,12.418H43.438ZM38.0591,25.59082h3.752l-1.84961-5.44727h-.05176Z" style="fill: #fff"/>
     <path d="M55.15969,25.96973c0,2.81348-1.50586,4.62109-3.77832,4.62109a3.0693,3.0693,0,0,1-2.84863-1.584h-.043v4.48438h-1.8584V21.44238H48.4302v1.50586h.03418a3.21162,3.21162,0,0,1,2.88281-1.60059C53.645,21.34766,55.15969,23.16406,55.15969,25.96973Zm-1.91016,0c0-1.833-.94727-3.03809-2.39258-3.03809-1.41992,0-2.375,1.23047-2.375,3.03809,0,1.82422.95508,3.0459,2.375,3.0459C52.30227,29.01563,53.24953,27.81934,53.24953,25.96973Z" style="fill: #fff"/>
     <path d="M65.12453,25.96973c0,2.81348-1.50586,4.62109-3.77832,4.62109a3.0693,3.0693,0,0,1-2.84863-1.584h-.043v4.48438h-1.8584V21.44238H58.395v1.50586h.03418A3.21162,3.21162,0,0,1,61.312,21.34766C63.60988,21.34766,65.12453,23.16406,65.12453,25.96973Zm-1.91016,0c0-1.833-.94727-3.03809-2.39258-3.03809-1.41992,0-2.375,1.23047-2.375,3.03809,0,1.82422.95508,3.0459,2.375,3.0459C62.26711,29.01563,63.21438,27.81934,63.21438,25.96973Z" style="fill: #fff"/>
     <path d="M71.71047,27.03613c.1377,1.23145,1.334,2.04,2.96875,2.04,1.56641,0,2.69336-.80859,2.69336-1.91895,0-.96387-.67969-1.541-2.28906-1.93652l-1.60937-.3877c-2.28027-.55078-3.33887-1.61719-3.33887-3.34766,0-2.14258,1.86719-3.61426,4.51855-3.61426,2.624,0,4.42285,1.47168,4.4834,3.61426h-1.876c-.1123-1.23926-1.13672-1.9873-2.63379-1.9873s-2.52148.75684-2.52148,1.8584c0,.87793.6543,1.39453,2.25488,1.79l1.36816.33594c2.54785.60254,3.60645,1.626,3.60645,3.44238,0,2.32324-1.85059,3.77832-4.79395,3.77832-2.75391,0-4.61328-1.4209-4.7334-3.667Z" style="fill: #fff"/>
     <path d="M83.34621,19.2998v2.14258h1.72168v1.47168H83.34621v4.99121c0,.77539.34473,1.13672,1.10156,1.13672a5.80752,5.80752,0,0,0,.61133-.043v1.46289a5.10351,5.10351,0,0,1-1.03223.08594c-1.833,0-2.54785-.68848-2.54785-2.44434V22.91406H80.16262V21.44238H81.479V19.2998Z" style="fill: #fff"/>
     <path d="M86.065,25.96973c0-2.84863,1.67773-4.63867,4.29395-4.63867,2.625,0,4.29492,1.79,4.29492,4.63867,0,2.85645-1.66113,4.63867-4.29492,4.63867C87.72609,30.6084,86.065,28.82617,86.065,25.96973Zm6.69531,0c0-1.9541-.89551-3.10742-2.40137-3.10742s-2.40039,1.16211-2.40039,3.10742c0,1.96191.89453,3.10645,2.40039,3.10645S92.76027,27.93164,92.76027,25.96973Z" style="fill: #fff"/>
     <path d="M96.18606,21.44238h1.77246v1.541h.043a2.1594,2.1594,0,0,1,2.17773-1.63574,2.86616,2.86616,0,0,1,.63672.06934v1.73828a2.59794,2.59794,0,0,0-.835-.1123,1.87264,1.87264,0,0,0-1.93652,2.083v5.37012h-1.8584Z" style="fill: #fff"/>
     <path d="M109.3843,27.83691c-.25,1.64355-1.85059,2.77148-3.89844,2.77148-2.63379,0-4.26855-1.76465-4.26855-4.5957,0-2.83984,1.64355-4.68164,4.19043-4.68164,2.50488,0,4.08008,1.7207,4.08008,4.46582v.63672h-6.39453v.1123a2.358,2.358,0,0,0,2.43555,2.56445,2.04834,2.04834,0,0,0,2.09082-1.27344Zm-6.28223-2.70215h4.52637a2.1773,2.1773,0,0,0-2.2207-2.29785A2.292,2.292,0,0,0,103.10207,25.13477Z" style="fill: #fff"/>
   </g>
 </g>
</g>
<g id="_Group_4" data-name="&lt;Group&gt;">
 <g>
   <path d="M37.82619,8.731a2.63964,2.63964,0,0,1,2.80762,2.96484c0,1.90625-1.03027,3.002-2.80762,3.002H35.67092V8.731Zm-1.22852,5.123h1.125a1.87588,1.87588,0,0,0,1.96777-2.146,1.881,1.881,0,0,0-1.96777-2.13379h-1.125Z" style="fill: #fff"/>
   <path d="M41.68068,12.44434a2.13323,2.13323,0,1,1,4.24707,0,2.13358,2.13358,0,1,1-4.24707,0Zm3.333,0c0-.97607-.43848-1.54687-1.208-1.54687-.77246,0-1.207.5708-1.207,1.54688,0,.98389.43457,1.55029,1.207,1.55029C44.57522,13.99463,45.01369,13.42432,45.01369,12.44434Z" style="fill: #fff"/>
   <path d="M51.57326,14.69775h-.92187l-.93066-3.31641h-.07031l-.92676,3.31641h-.91309l-1.24121-4.50293h.90137l.80664,3.436h.06641l.92578-3.436h.85254l.92578,3.436h.07031l.80273-3.436h.88867Z" style="fill: #fff"/>
   <path d="M53.85354,10.19482H54.709v.71533h.06641a1.348,1.348,0,0,1,1.34375-.80225,1.46456,1.46456,0,0,1,1.55859,1.6748v2.915h-.88867V12.00586c0-.72363-.31445-1.0835-.97168-1.0835a1.03294,1.03294,0,0,0-1.0752,1.14111v2.63428h-.88867Z" style="fill: #fff"/>
   <path d="M59.09377,8.437h.88867v6.26074h-.88867Z" style="fill: #fff"/>
   <path d="M61.21779,12.44434a2.13346,2.13346,0,1,1,4.24756,0,2.1338,2.1338,0,1,1-4.24756,0Zm3.333,0c0-.97607-.43848-1.54687-1.208-1.54687-.77246,0-1.207.5708-1.207,1.54688,0,.98389.43457,1.55029,1.207,1.55029C64.11232,13.99463,64.5508,13.42432,64.5508,12.44434Z" style="fill: #fff"/>
   <path d="M66.4009,13.42432c0-.81055.60352-1.27783,1.6748-1.34424l1.21973-.07031v-.38867c0-.47559-.31445-.74414-.92187-.74414-.49609,0-.83984.18213-.93848.50049h-.86035c.09082-.77344.81836-1.26953,1.83984-1.26953,1.12891,0,1.76563.562,1.76563,1.51318v3.07666h-.85547v-.63281h-.07031a1.515,1.515,0,0,1-1.35254.707A1.36026,1.36026,0,0,1,66.4009,13.42432Zm2.89453-.38477v-.37646l-1.09961.07031c-.62012.0415-.90137.25244-.90137.64941,0,.40527.35156.64111.835.64111A1.0615,1.0615,0,0,0,69.29543,13.03955Z" style="fill: #fff"/>
   <path d="M71.34816,12.44434c0-1.42285.73145-2.32422,1.86914-2.32422a1.484,1.484,0,0,1,1.38086.79h.06641V8.437h.88867v6.26074h-.85156v-.71143h-.07031a1.56284,1.56284,0,0,1-1.41406.78564C72.0718,14.772,71.34816,13.87061,71.34816,12.44434Zm.918,0c0,.95508.4502,1.52979,1.20313,1.52979.749,0,1.21191-.583,1.21191-1.52588,0-.93848-.46777-1.52979-1.21191-1.52979C72.72121,10.91846,72.26613,11.49707,72.26613,12.44434Z" style="fill: #fff"/>
   <path d="M79.23,12.44434a2.13323,2.13323,0,1,1,4.24707,0,2.13358,2.13358,0,1,1-4.24707,0Zm3.333,0c0-.97607-.43848-1.54687-1.208-1.54687-.77246,0-1.207.5708-1.207,1.54688,0,.98389.43457,1.55029,1.207,1.55029C82.12453,13.99463,82.563,13.42432,82.563,12.44434Z" style="fill: #fff"/>
   <path d="M84.66945,10.19482h.85547v.71533h.06641a1.348,1.348,0,0,1,1.34375-.80225,1.46456,1.46456,0,0,1,1.55859,1.6748v2.915H87.605V12.00586c0-.72363-.31445-1.0835-.97168-1.0835a1.03294,1.03294,0,0,0-1.0752,1.14111v2.63428h-.88867Z" style="fill: #fff"/>
   <path d="M93.51516,9.07373v1.1416h.97559v.74854h-.97559V13.2793c0,.47168.19434.67822.63672.67822a2.96657,2.96657,0,0,0,.33887-.02051v.74023a2.9155,2.9155,0,0,1-.4834.04541c-.98828,0-1.38184-.34766-1.38184-1.21582v-2.543h-.71484v-.74854h.71484V9.07373Z" style="fill: #fff"/>
   <path d="M95.70461,8.437h.88086v2.48145h.07031a1.3856,1.3856,0,0,1,1.373-.80664,1.48339,1.48339,0,0,1,1.55078,1.67871v2.90723H98.69v-2.688c0-.71924-.335-1.0835-.96289-1.0835a1.05194,1.05194,0,0,0-1.13379,1.1416v2.62988h-.88867Z" style="fill: #fff"/>
   <path d="M104.76125,13.48193a1.828,1.828,0,0,1-1.95117,1.30273A2.04531,2.04531,0,0,1,100.73,12.46045a2.07685,2.07685,0,0,1,2.07617-2.35254c1.25293,0,2.00879.856,2.00879,2.27V12.688h-3.17969v.0498a1.1902,1.1902,0,0,0,1.19922,1.29,1.07934,1.07934,0,0,0,1.07129-.5459Zm-3.126-1.45117h2.27441a1.08647,1.08647,0,0,0-1.1084-1.1665A1.15162,1.15162,0,0,0,101.63527,12.03076Z" style="fill: #fff"/>
 </g>
</g>
</g>
</svg>
</a>
          </v-col>
          <v-col class="pa-0 mt-n3 text-left">
            <a
                    href='https://play.google.com/store/apps/details?id=com.vishvanathaachary.ispent&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img
                      alt='Get it on Google Play'
                      src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png' />
                  </a>

          </v-col>
         <!-- <v-col class="col-md-1 text-left pa-0 ma-0">
            <v-img src="free.png" width="68px" height="30px"></v-img>
          </v-col>-->
        </v-row>
      </div>  
      </v-card-title>
      <v-card-text class="pa-3">
        <div>
          <hr />
          <v-row>
            <v-col>
              <h1 class="font-weight-black text-center mt-4 subHeader"> <span style="color:#1976d2">iSpent&#8482;</span> has been
                rated as
                the best mobile
                app for personal finance
              </h1>
            </v-col>
          </v-row>
          <v-row class="mt-n3">
            <v-col>
              <v-row>
                <v-col class="text-center" color="black darken-1">
                  <div style="background-color:#E8ECEC">
                    <v-img src="award.jfif" height="40vh" title="Awarded" alt="iSpent Award" />
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <h2 class="font-weight-light text-font">
                    <span class="font-weight-bold text-uppercase">BANGALORE</span>
                    : iSpent, An expenses and income tracker mobile app typically offers a range of
                    features to
                    help
                    users manage their financial transactions and monitor their financial health. Tracking expenses and
                    budgeting income provide a clear understanding of where your money is coming from and where it's
                    going.
                    This awareness is crucial for making informed financial decisions and avoiding overspending.
                  </h2>
                  <p class="font-weight-light text-font mt-2"><b class="font-weight-bold">Control Over Finances:</b> By
                    monitoring your expenses and income, you gain control over your financial situation. You can make
                    conscious choices about spending, saving, and investing, rather than letting money decisions control
                    you.</p>
                  <p class="font-weight-light text-font"><b class="font-weight-bold">Preventing Overspending:</b> Regular
                    expense tracking helps you identify unnecessary or excessive spending patterns. With this insight, you
                    can make adjustments to stay within your budget and avoid accumulating debt.</p>
                  <p class="font-weight-light text-font"><b class="font-weight-bold">Debt Management:</b> Effective
                    budgeting can help you allocate funds for debt repayment and avoid taking on new debt. This is
                    especially important for managing credit card balances and loans.</p>
                  <p class="font-weight-light text-font"><b class="font-weight-bold">Saving and Investing:</b> A budget
                    allows you to allocate funds for savings and investments, helping you work toward financial goals such
                    as building an emergency fund, saving for a down payment on a house, or planning for retirement.</p>
                  <p class="font-weight-light text-font"><b class="font-weight-bold">Goal Achievement:</b> When you track
                    your expenses and budget your income, you can set and achieve specific financial goals. These could
                    include paying off debt, funding a vacation, buying a car, or pursuing higher education.</p>
                  <p class="font-weight-light text-font"><b class="font-weight-bold">Improved Financial
                      Decision-Making:</b>
                    Armed with accurate financial information, you can make better decisions about major purchases,
                    investments, and other financial commitments.</p>
                  <p class="font-weight-light text-font"><b class="font-weight-bold">Adjusting Priorities:</b> Regularly
                    reviewing your budget allows you to reassess your financial priorities. As circumstances change, you
                    can
                    adjust your budget to reflect new goals or changes in income and expenses.</p>
                  <p class="font-weight-light text-font"><b class="font-weight-bold">Reducing Stress:</b> Financial
                    uncertainty can be a significant source of stress. Budgeting and expense tracking provide a sense of
                    control and confidence, reducing stress related to money matters.</p>
                </v-col>
              </v-row>
            </v-col>
            <v-col>
              <p class="font-weight-light text-font">iSpent, An expenses and income tracker mobile app typically offers a
                range of
                features to help users manage their financial transactions and monitor their financial health. The data is
                securely stored in the mobile device's memory, and unauthorized access is denied. Here are some common
                features you might find in such an app:</p>
              <p class="font-weight-light text-font"><b class="font-weight-bold">Expense Tracking:</b> Record and
                categorize
                daily expenses, such as groceries, dining, transportation, entertainment, etc. Set spending limits for
                various categories. View expense history and trends over time.</p>
              <p class="font-weight-light text-font"><b class="font-weight-bold">Income Tracking:</b> Log various sources
                of
                income, like salary, freelance earnings, investments, etc. Track your total income and monitor income
                trends.</p>
              <p class="font-weight-light text-font"><b class="font-weight-bold">Budgeting:</b> Create and manage budgets
                for different categories. Set financial goals and track progress toward them. Receive alerts when you
                approach or exceed budget limits.</p>
              <p class="font-weight-light text-font"><b class="font-weight-bold">Categorization and Tags:</b> Assign
                customizable categories and tags to expenses and income for detailed tracking and reporting.</p>
              <p class="font-weight-light text-font"><b class="font-weight-bold">Reports and Analytics:</b> Generate
                various
                financial reports, such as expense summaries, income summaries, balance sheets, and more. Visualize
                spending
                patterns through charts and graphs. Analyze spending habits and identify areas for improvement.</p>
              <p class="font-weight-light text-font"><b class="font-weight-bold">Offline Access:</b> Ability to use the
                app
                and make entries even when offline. Internet connection or mobile data is not required to use this app.
                The
                data is securely stored in the mobile device's memory, and unauthorized access is denied.</p>
              <p class="font-weight-light text-font"><b class="font-weight-bold">Customization:</b> Personalize
                categories,
                labels, and other settings to suit your financial habits and preferences.</p>
              <p class="font-weight-light text-font"><b class="font-weight-bold">Security and Privacy:</b> Since the data
                is
                stored in your mobile device's memory, outsiders cannot access your data, ensuring a highly secure
                environment right in your hands. Storing data on the mobile device's memory means the data is physically
                present on the device itself. This reduces the exposure of data to external networks and servers, which
                can
                minimize the risk of remote attacks.</p>
              <p class="font-weight-light text-font"><b class="font-weight-bold">Planning for the Future:</b> Budgeting
                income and tracking expenses are integral to long-term financial planning. They help you build a roadmap
                for
                achieving your financial aspirations, whether it's buying a home, sending your kids to college, or
                retiring
                comfortably.</p>
              <p class="font-weight-light text-font"><b class="font-weight-bold">Financial Accountability:</b>
                Accountability to your budget and expense tracking can prevent impulsive buying and encourage responsible
                spending habits.</p>
              <p class="font-weight-light text-font"><b class="font-weight-bold">Avoiding Financial Surprises:</b> Regular
                tracking helps you spot irregularities or unexpected changes in your financial situation early, allowing
                you
                to take corrective action before problems escalate.</p>
              <p class="font-weight-light text-font"><b class="font-weight-bold">Financial Independence:</b> With
                effective
                budgeting and expense tracking, you're more likely to become financially independent and self-reliant over
                time.</p>
            </v-col>
          </v-row>
          <v-row class="mt-n3">
            <v-col>
              <h3 class="font-weight-light text-font mt-n5">
                    In summary, tracking expenses, income, and budgeting using iSpent are essential tools for achieving financial
                    security, managing your money effectively, and making informed financial decisions. Whether you're an
                    individual, a household, or a business, these practices provide a roadmap to navigate your financial
                    journey successfully.
              </h3>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-list-item-content>
                <iframe width="360" height="315" src="https://www.youtube.com/embed/_HcnL3wRhdM?si=7P-JR7wF8MNuYvgP" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
              </v-list-item-content>
            </v-col>
            <v-col>
              <v-list-item-content>
                <div style="position: relative; overflow: hidden; padding-top: 56.25%;"><iframe src="https://share.synthesia.io/embeds/videos/20750611-cf1a-411b-a5cd-952ed31f40f4" loading="lazy" title="Synthesia video player - Your AI video" allow="encrypted-media; fullscreen;" style="position: absolute; width: 100%; height: 100%; top: 0; left: 0; border: none; padding: 0; margin: 0; overflow:hidden;"></iframe></div>
            </v-list-item-content> 

            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-list-item-avatar color="grey darken-3">
                <v-img class="elevation-6" src="vish.jpg"></v-img>
              </v-list-item-avatar>
              <v-list-item-title class="text-uppercase font-weight-regular text-caption">Vishvanatha Achary
              </v-list-item-title>
              <div class="font-weight-bold font-feedback">
                "I am grateful for the chance to review the code crafted by highly skilled technical professionals, even
                amid my hectic schedule. This app has been developed using a renowned framework. Our technical team is
                always at your disposal to resolve any technical issues. Thanks to the team for your tremendous effort,
                and I wish you all the best in your endeavors."
              </div>
            </v-col>
            <v-col>
              <v-list-item-avatar color="grey darken-3">
                <v-img class="elevation-6" src="james.jpg"></v-img>
              </v-list-item-avatar>
              <v-list-item-title class="text-uppercase font-weight-regular text-caption">James Madison
                &#9733;&#9733;&#9733;&#9733;&#9733;

              </v-list-item-title>
              <div class="font-weight-bold font-feedback">
                "This is the best daily expense tracking app I've tried so far, and I don't think it can get any better
                than
                this! It's easy to use, completely customizable with a range of icons and colors. Plus, you can see the
                expenses per transaction, as well as an overhead view. The best feature yet is that you can view your
                expenses in a pie chart or bar diagram to see where you're spending the most! I think this app deserves an
                award for being so neat and easy to use."
              </div>
            </v-col>
            <v-col>
              <v-list-item-avatar color="grey darken-3">
                <v-img class="elevation-6" src="jaggu.png"></v-img>
              </v-list-item-avatar>
              <v-list-item-title class="text-uppercase font-weight-regular text-caption">Dr. Jagadeesh Krishna
                &#9733;&#9733;&#9733;&#9733;&#9733;
              </v-list-item-title>
              <div class="font-weight-bold font-feedback">
                "iSpent is a valuable app, if you seeking for good expenses and income tracking tool. Its user-friendly
                design and straightforward approach make it an excellent choice for individuals who want to gain a better
                understanding of their spending habits without the complexity of more advanced financial apps."
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-list-item-avatar color="grey darken-3">
                <v-img class="elevation-6" src="naveena.jpg" alt="naveena"></v-img>
              </v-list-item-avatar>
              <v-list-item-title class="text-uppercase font-weight-regular text-caption">Naveena Bhandari K
                &#9733;&#9733;&#9733;&#9733;&#9733;
              </v-list-item-title>
              <div class="font-weight-bold font-feedback">
                "This app has functionality to track the expenses, cool feature about it, is 1.it has annual and monthly
                expense report 2. Graph representation of expense 3. Provides so many option of expenses which will remind
                us forgotten expenses A good to use app."
              </div>
            </v-col>
            <v-col>
              <v-list-item-avatar color="grey darken-3">
                <v-img class="elevation-6" src="avatar.png"></v-img>
              </v-list-item-avatar>
              <v-list-item-title class="text-uppercase font-weight-regular text-caption">ramesh yelanadu
                &#9733;&#9733;&#9733;&#9733;&#9733;
              </v-list-item-title>
              <div class="font-weight-bold font-feedback">
                "It's really works fine....is it possible to export the content into mail or Excel or msword....even
                though
                after changing the mobile also we can access the old expenses..... Overall application is very useful."
              </div>
            </v-col>
            <v-col>
              <v-list-item-avatar color="grey darken-3">
                <v-img class="elevation-6" src="avatar.png"></v-img>
              </v-list-item-avatar>
              <v-list-item-title class="text-uppercase font-weight-regular text-caption">Nicole Tng
                &#9733;&#9733;&#9733;&#9733;&#9733;
              </v-list-item-title>
              <div class="font-weight-bold font-feedback">
                "Simple n nice app to track my expenses."
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-list-item-avatar color="grey darken-3">
                <v-img class="elevation-6" src="avatar.png"></v-img>
              </v-list-item-avatar>
              <v-list-item-title class="text-uppercase font-weight-regular text-caption">Yaswanth Amin
                &#9733;&#9733;&#9733;&#9733;&#9733;
              </v-list-item-title>
              <div class="font-weight-bold font-feedback">
                "This is the best app for tracking our expenses and income. Damn easy interface with eye soothing design. I don't think any app available with such an easy usage. It is absolutely free app without any ads or registration. It works absolutely fine without internet. Now I started spending within my budget. I can easily analyse my monthly and early expenditure with bar and pie chart. This is what I was searching for a long time. Thanks to iSpent."
              </div>
            </v-col>
            <v-col>
              <v-list-item-avatar color="grey darken-3">
                <v-img class="elevation-6" src="avatar.png"></v-img>
              </v-list-item-avatar>
              <v-list-item-title class="text-uppercase font-weight-regular text-caption">CALL_ME_CARNAGE
                &#9733;&#9733;&#9733;&#9733;&#9733;
              </v-list-item-title>
              <div class="font-weight-bold font-feedback">
                "Really enjoyed the interface for this app. Keeps things simple and has an interface that's as easy on the eyes as it is time navigate. This app let's me manually add transactions and show me how much money I have remaining in my monthly budget. It gives me an opportunity to easily view my remaining budget before spending money, which is all I need. Keep it simple. Thank you so much iSpent ❤"
              </div>
            </v-col>
            <v-col>
              <v-list-item-avatar color="grey darken-3">
                <v-img class="elevation-6" src="avatar.png"></v-img>
              </v-list-item-avatar>
              <v-list-item-title class="text-uppercase font-weight-regular text-caption">Shiva Kumar
                &#9733;&#9733;&#9733;&#9733;&#9733;
              </v-list-item-title>
              <div class="font-weight-bold font-feedback">
                "Absolutely stunning. The app is incredibly easy to use and the pie chart feature is so helpful. Furthermore and more, the UI creates a positive view somehow with it's color choices and over look and feel as well as functional that actually makes me want task to track expenses. User friendly and track my expenses and Income."
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <div class="google-maps">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3879.538057523358!2d74.83390707344927!3d13.50253820301901!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bbc9975a15e2493%3A0xe91744f0be2dd3f!2siSpent%20Development%20Center!5e0!3m2!1sen!2sin!4v1692453517742!5m2!1sen!2sin"
                  width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"></iframe>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-card class="mx-auto">
                <v-card-text>
                  <p class="text--primary">
                    <v-icon>mdi-school</v-icon> Sri Jayachamaraja Usru Education Trust<br>
                    <span class="text-h6">Sri Vanivilasa Usru Women's First Grade College</span>
                  </p>
                  <p>Office : <v-icon>mdi-phone-classic</v-icon> 0821-2444588 Nazarbad, Mysore - 570010<br>
                    <v-icon>mdi-gmail</v-icon> svvuwfgc@gmail.com <v-icon>mdi-web</v-icon> www.sjuet.com
                  </p>
                  <div class="text--primary">
                    Dr. Jagadeesh Krishna MSc.,Ph.D, (KSLET) Principal <v-icon>mdi-phone-incoming</v-icon> 9590905095
                  </div>
                </v-card-text>
                <v-card-actions>
                  <div class="bg-dark text-white">
                    Course: BCA | B.Com | BBA | BA
                  </div>
                </v-card-actions>
              </v-card>


            </v-col>
            <v-col>
              <v-card class="mx-auto pa-2">
                <v-card-text>
                  <div>
                    <div class="text--primary row">
                      <div class="col-md-5">
                        <v-img src="lic.png" alt="LIC" width="176" height="96"></v-img>
                      </div>
                      <div class="col-md-7">
                        <span class="text-h6 ">For all types of LIC policies, please contact us.</span><br>
                        Agent Code: 02345301
                      </div>
                    </div>
                    <!-- <div class="row">
                      <v-icon>mdi-gmail</v-icon> shubharathna.acharya@gmail.com
                    </div> -->
                    <div class="text--primary row pl-4">
                      SHUBHARATHNA | LIC Agent <v-icon>mdi-phone-incoming</v-icon> 9740523027
                    </div>
                  </div>
                </v-card-text>
                <v-card-actions>

                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>

        </div>
      </v-card-text>
      <v-card-actions>

      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
//import db from "@/fb";
//import AddNews from "@/components/AddNews";
export default {
  name: "news",
  // components: { AddNews },
  data: () => ({
    snackbar: false,
    items: [],
  }),
  methods: {
    getEntryTime: function () {
      var currentDate = new Date();
      currentDate.setDate(currentDate.getDate());
      var month = new Array();
      month[0] = "January";
      month[1] = "February";
      month[2] = "March";
      month[3] = "April";
      month[4] = "May";
      month[5] = "June";
      month[6] = "July";
      month[7] = "August";
      month[8] = "September";
      month[9] = "October";
      month[10] = "November";
      month[11] = "December";
      var day = new Array();
      day[0] = "Sunday";
      day[1] = "Monday";
      day[2] = "Tuesday";
      day[3] = "Wednesday";
      day[4] = "Thursday";
      day[5] = "Friday";
      day[6] = "Saturday";
      var monName = month[currentDate.getMonth()];
      var currentDay = day[currentDate.getDay()];
      var currentDateWithFormat =
        currentDay +
        ", " +
        monName +
        " " +
        currentDate.getDate() +
        ", " +
        currentDate.getFullYear();
      return currentDateWithFormat;
    },
    /*fetchdata() {
      db.collection("news")
        //.where("id", "==", "ghlML9lrwpujjCcBwKCw")
        //.orderBy('EntryTime')
        .onSnapshot(res => {
          const changes = res.docChanges();
          changes.forEach(change => {
            if (change.type === "added") {
              this.items.push({
                ...change.doc.data(),
                id: change.doc.id
              });
            }
          });
        })

        ;
    },*/
  },
  /*created: function () {
    this.fetchdata();
  },
  watch: {
    "$route.path": "fetchdata"
  },*/
};
</script>
<style scoped>
body {
  background: black;
}

.google-maps {
  position: relative;
  padding-bottom: 75%;
  height: 0;
  overflow: hidden;
}

.google-maps iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}

Licimg {
  height: 100vh;
}

img {
  border: 1px solid white;
  border-radius: 6px;
  box-sizing: border-box;
  height: 65px;
}

hr {
  position: relative;
  top: 0px;
  border: none;
  height: 10px;
  background: gray;
  margin-bottom: 0px;
}

.font-sub-title {
  font-size: 25px;
}

.font-feedback {
  font-family: "Arial Narrow";
  font-size: 13px;
  line-height: 13px;
  font-weight: bold;
}

.containerRotate {
  border: solid 1px;
  transform: rotateY(0deg) rotate(0.0deg);
  /* needs Y at 0 deg to behave properly*/
  transition: transform 2s;
}

.containerRotate1:hover {
  transform: rotateY(180deg) rotate(45deg);
  /* notice the order */
}

.text-font {
  font-family: Georgia, "Times New Roman", Times, serif;
  font-size: 14px;
  line-height: 19px;
}

@media (min-width: 200px) {
  div.headlineText {
    font-size: 25px;
    text-align: center;
    font-family: 'Quicksand';
  }

  h1.subHeader {
    font-size: 20px;
    font-family:Trebuchet MS;
  }

  .containerRotate {
    border: solid 1px;
    transform: rotateY(0deg) rotate(0deg);
    /* needs Y at 0 deg to behave properly*/
    transition: transform 2s;
  }

  .font-sub-title {
    font-size: 12px;
  }
}

@media (min-width: 800px) {
  div.headlineText {
    font-size: 68px;
    text-align: center;
    font-family: 'Quicksand';
  }

  h1.subHeader {
    font-size: 25px;
    text-shadow: 1px 0px grey;
    font-family: Trebuchet MS;
  }

  .font-sub-title {
    font-size: 20px;
  }
}

@media (min-width: 1000px) {
  div.headlineText {
    font-size: 60px;
    text-align: center;
    font-family: 'Quicksand';
  }

  h1.subHeader {
    font-size: 25px;
    text-shadow: 1px 0px grey;
    font-family: Trebuchet MS;
  }

  .containerRotate {
    border: solid 1px;
    transform: rotateY(0deg) rotate(0deg);
    /* needs Y at 0 deg to behave properly*/
    transition: transform 2s;
  }

  .font-sub-title {
    font-size: 20px;
  }
}</style>